import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"

// import Layout from "src/component/Layout"
import MainPage from "src/component/MainPage"

export default ({ data }) => <MainPage data={data} />

export const pageQuery = graphql`
  query HomePage {
    photographyImage: file(
      relativePath: { eq: "amster_shadows_gray.jpg" }
      sourceInstanceName: { eq: "assets" }
    ) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    portfolioImage: file(
      relativePath: { eq: "deathing_gray.jpg" }
      sourceInstanceName: { eq: "assets" }
    ) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    psychologyImage: file(
      relativePath: { eq: "eye_gray.jpg" }
      sourceInstanceName: { eq: "assets" }
    ) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
