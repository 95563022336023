import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

// import Layout from "src/component/Layout/LayoutWhite"
import Layout from "src/component/Layout"
import css from "./style.module.css"

const MainPage = ({ data }) => {
  return (
    <Layout>
      <div className={css.content}>
        <Link to={"/portfolio"} className={css.section}>
          <Img fixed={data.portfolioImage.childImageSharp.fixed} />
          <div>creative</div>
        </Link>
        <Link to={"/photo"} className={css.section}>
          <Img fixed={data.photographyImage.childImageSharp.fixed} />
          <div>photography</div>
        </Link>
        <Link to={"/psychology"} className={css.section}>
          <Img fixed={data.psychologyImage.childImageSharp.fixed} />
          <div>psychology</div>
        </Link>
      </div>
    </Layout>
  )
}

export default MainPage
